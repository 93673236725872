import { useState } from 'react';
import { articlesList } from '../json/articles'

import { isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player'
import { useHover } from "@uidotdev/usehooks";

import '../App.css';
import Nav from 'react-bootstrap/Nav';
import { Navbar, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Facebook, Instagram,  Phone,  Telephone,  TelephoneFill,  Tiktok, Whatsapp, XCircle } from 'react-bootstrap-icons';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Carousel } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import hop from '../img/HOP.png'
import orto from '../img/ortosl.png'
import hope from '../img/hope.png'
import clean from '../img/blanq.png'
import gbt from '../img/gbt.png'

import consul1 from '../img/home_carousel/c1.png'
import consul2 from '../img/home_carousel/c2.png'
import consul3 from '../img/home_carousel/c3.png'
import consul4 from '../img/home_carousel/c4.png'
import consul5 from '../img/home_carousel/c5.png'
import consul6 from '../img/home_carousel/c6.png'
import consul7 from '../img/home_carousel/c7.png'
import consul8 from '../img/home_carousel/c8.png'
import consul9 from '../img/home_carousel/c9.png'
import consul10 from '../img/home_carousel/c10.png'
import consul11 from '../img/home_carousel/c11.png'
import consul12 from '../img/home_carousel/c12.png'
import consul13 from '../img/home_carousel/c13.png'
import consul14 from '../img/home_carousel/c14.png'
import consul15 from '../img/home_carousel/c15.png'
import consul16 from '../img/home_carousel/c16.png'
import consul17 from '../img/home_carousel/c17.png'
import consul18 from '../img/home_carousel/c18.png'

import ini from '../img/ini.png'
import doc from '../img/doc.png'
import anterior from '../img/anterior1.png'
import siguiente from '../img/siguiente2.png'

import alberto from '../img/doctores/alberto.png'
import gabriela from '../img/doctores/gabriela.png'
import isaac from '../img/doctores/isaac.png'
import lupita from '../img/doctores/lupita.png'
import karent from '../img/doctores/karent.png'
import mildred from '../img/doctores/mildred.png'
import alejandro from '../img/doctores/alejandro.png'
import israel from '../img/doctores/israel.png'
import ximena from '../img/doctores/ximena.png'

import alberto_a from '../img/doctores/alberto_a.png'
import gabriela_a from '../img/doctores/gabriela_a.png'
import isaac_a from '../img/doctores/isaac_a.png'
import lupita_a from '../img/doctores/lupita_a.png'
import karent_a from '../img/doctores/karent_a.png'
import mildred_a from '../img/doctores/mildred_a.png'
import alejandro_a from '../img/doctores/alejandro_a.png'
import israel_a from '../img/doctores/israel_a.png'
import ximena_a from '../img/doctores/ximena_a.png'

import blog1 from '../img/blog1.png'
import blog2 from '../img/blog2.png'
import blog3 from '../img/blog3.png'
import rod from '../img/rod.png'
import flecha from '../img/flecha.png'

import testimonio1 from '../img/testimoniales/testimonio1.png'
import testimonio2 from '../img/testimoniales/testimonio2.png'
import testimonio3 from '../img/testimoniales/testimonio3.png'
import testimonio4 from '../img/testimoniales/testimonio4.png'
import testimonio5 from '../img/testimoniales/testimonio5.png'

const testimonios = [
  {
    title: 'María Alejandra Guerrero',
    desc: 'Resido en Canadá, donde acudí al dentista y me dijeron que me realizarían una endodoncia. Sin embargo, aproveché mi visita a México e iniciar aquí mi tratamiento, que resultó en un puente completo. Es en definitiva una enorme diferencia en costo y atención, mi experiencia ha sido magnífica, son unos excelentes profesionistas, te hablan con la verdad, te enseñan radiografías y te dan opciones de tratamientos. Definitivamente recomendaría D-Natural a todos mis conocidos y familiares.',
    img: testimonio1
  },
  {
    title: 'Paulina Espinoza',
    desc: 'Asisto a D-Natural por un tratamiento de ortodoncia y me quitaron caries. La atención es muy buena y cada que asisto sé que voy a recibir un trato profesional y a la vez divertido, pues durante el proceso platicas, te sientes en un lugar muy cómodo y siempre te reciben con los brazos abiertos. Totalmente recomiendo que vengan a D-Natural porque los dentistas son increíbles y siempre hacen un buen trabajo.',
    img: testimonio2
  },
  {
    title: 'Marien Torres Segura',
    desc: 'Ahora me están haciendo un tratamiento para alinear los dientes, con la nueva tecnología Clear Correct, que consiste en utilizar alineadores cada mes. Soy paciente desde que era pequeña y siempre el servicio ha sido muy bueno, siempre se aseguran de que estés bien, que no tengas miedo ni dolor. Por ejemplo, cuando me sacaron las muelas del juicio yo tenía mucho miedo, pero fueron súper amables y no sentí ningún dolor. Si eres de las personas que te da miedo acudir al dentista este lugar es para ti, porque no vas a sentir ni miedo, ni angustia ni dolor. Todos en su equipo están súper capacitados y son excelentes personas.',
    img: testimonio3
  },
  {
    title: 'Liliana Ruíz Esparza',
    desc: 'Me hicieron un arreglo en los dientes porque se me rompió el diente frontal. Tengo muchísima confianza con ellos y siempre han sido súper respetuosos y amables durante estos cuatro años que he sido paciente. Recomendaría totalmente a D-Natural.',
    img: testimonio4
  },
  {
    title: 'Jorge Sampeiro',
    desc: 'Me hice una limpieza con Air Flow, me agradó bastante y es muy satisfactorio. No es molesto, es veloz y, sobre todo, me gusta que primero se haga una identificación de la concentración de bacterias para mejorar la higiene con el cepillado dental.',
    img: testimonio5
  }
]

function Home() { 
  const [ showDoctor, setShowDoctor ] = useState(false)
  const [ showMarketing, setShowMarketing ] = useState(false)
  const [ img, setImg ] = useState(null)
  const [ title, setTitle ] = useState('')
  const [ desc, setDesc ] = useState('')

  const [refAlberto, hoveringAlberto] = useHover();
  const [refGabriela, hoveringGabriela] = useHover();
  const [refIsaac, hoveringIsaac] = useHover();
  const [refLupita, hoveringLupita] = useHover();
  const [refKarent, hoveringKarent] = useHover();
  const [refMildred, hoveringMildred] = useHover();
  const [refAlejandro, hoveringAlejandro] = useHover();
  const [refIsrael, hoveringIsrael] = useHover();
  const [refXimena, hoveringXimena] = useHover();

  const doctores = [
    /*{
      id: 0,
      img: gabriela,
      img_b: gabriela_a,
      title: 'Dra. Gabriela',
      desc: 'Lo que más me apasiona es pasar tiempo con mi familia, salir a caminar con mi perrita Ana Chule y jugar con ella. Me encanta viajar y conocer lugares nuevos, la cultura y comida de otros países. Me fascinan los helados Moyo con frutas.',
      ref: refGabriela,
      hover: hoveringGabriela
    },*/
    {
      id: 0,
      img: ximena,
      img_b: ximena_a,
      title: 'Dra. Ximena',
      desc: 'Soy muy espontánea y simpática, amo ir al gym con mis amigos, salir a pasear, me encanta arreglarme el cabello y maquillarme, uno de mis hobbies es bailar y cantar. Mi adoración son mis dos gatitos Milko y Nina.',
      ref: refXimena,
      hover: hoveringXimena
    },
    {
      id: 1,
      img: isaac,
      img_b: isaac_a,
      title: 'Dr. Isaac',
      desc: 'Me gusta todo lo relacionado con el grill y los asados, me encanta el agua de maracuyá y soy amante de la playa. Al atender pacientes, me apasiona apreciar cuando hemos generado un cambio radical a nivel estético y funcional.',
      ref: refIsaac,
      hover: hoveringIsaac
    }, {
      id: 2,
      img: lupita,
      img_b: lupita_a,
      title: 'Dra. Lupita',
      desc: 'Me encanta ir al cine y ver series, amo andar en bici y coleccionar maquillaje. Me gustan los perros, en especial los pomerania. Mi comida favorita es la pizza y las papas a la francesa.',
      ref: refLupita,
      hover: hoveringLupita
    }, 
    /*{
      id: 3,
      img: karent,
      img_b: karent_a,
      title: 'Dra. Karent',
      desc: 'Me encanta leer, amo pasar el tiempo con mi familia y mi mascota favorita es un pequeño y bonito hámster. Me fascina viajar a diferentes sitios, aprender y conocer todo de ellos. Mi pasión es capturar en fotografías los colores del cielo, porque podrás ir al mismo lugar cien veces, pero jamás verás el mismo color otra vez.',
      ref: refKarent,
      hover: hoveringKarent
    },*/ 
    {
      id: 4,
      img: mildred,
      img_b: mildred_a,
      title: 'Dra. Mildred',
      desc: 'Me gusta mucho cocinar comida italiana y repostería. Me encanta los perros, pasar tiempo con mi familia, oír música y bailar. El café y el chocolate me vuelven loca. Mi deporte favorito es el fútbol.',
      ref: refMildred,
      hover: hoveringMildred
    }, {
      id: 5,
      img: alejandro,
      img_b: alejandro_a,
      title: 'Dr. Alejandro',
      desc: 'Mis animales favoritos son los gatos y las ardillas, disfruto mucho de la naturaleza, es asombrosa. Me encantan las películas de terror. Mi debilidad es el chocolate blanco y el pan dulce. Soy team calor, me gustan los días soleados, patinar los domingos sobre Reforma y los días de playa.',
      ref: refAlejandro,
      hover: hoveringAlejandro
    }, {
      id: 6,
      img: israel,
      img_b: israel_a,
      title: 'Dr. Israel',
      desc: 'Me encantan los perros, el fútbol soccer, el americano, escuchar música y leer libros. ',
      ref: refIsrael,
      hover: hoveringIsrael
    }
  ]

  const opendoctor = (doctor) => {
    const found = doctores.find(element => element.id == doctor);

    console.log("found", found)
    if( found ) {
      console.log("in", found)
      setImg(found.img_b)
      setTitle(found.title)
      setDesc(found.desc)
      setShowDoctor(true)
    }
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 2 : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrows: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    pauseOnDotsHover: false
  };
    return (
        <>
        <Modal show={ showMarketing } centered onHide={ () => setShowMarketing(false) } size='lg' aria-labelledby="contained-modal-title-vcenter">
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <div>
              <ReactPlayer url='ad.mp4' playing={ true } loop={ true } muted={ true } controls={ true } className="player-ad-style"/>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={ showDoctor } size='lg' aria-labelledby="contained-modal-title-vcenter">
          <Modal.Body className="show-grid">
            <Container className='doctor-container'>
              <Row>
                <div align="right">
                  <XCircle className='close-btn' onClick={() => setShowDoctor(false) } />
                </div>
              </Row>
              <Row align="center">
                <div><img src={img} className='img-colab' /></div>
                <br/>
                <div className='equipo-trabajo-main'>{ title }</div>
                <div className='equipo-trabajo-principal-text'>
                  { desc }
                </div>
              </Row>
              <br/>
            </Container>
          </Modal.Body>
        </Modal>

            <div className='banner-bg' align="center">
              <ReactPlayer url='dnatural.mp4' playing={ true } loop={ true } muted={ true } controls={ false } className="player-style"/>
            </div>
      { !isMobile && <div className="clearfixe-body"></div> }
      <div className="banner-bg-ques" align="center">
        <Container>
          <Row className='align-items-center'>
            
            <Col md={5}>
              <div className="banner-container-ques" align="center">
                <div className="banner-text-container">
                  <div className="banner-title-ques">¿Qué es</div>
                  <div className="banner-title-ques-2">D-Natural?</div>
                  <div className="banner-desc-ques">
                    <div>D-Natural está integrado por un increíble equipo de trabajo, <span className='video'>que ama y disfruta lo que hace.</span> Se prepara continuamente desarrollando sus habilidades a través de capacitación continua, incluyendo nueva tecnología en su práctica diaria,
                    para que te sientas <span className='video'>orgulloso de tu sonrisa.</span>
                    </div>
                    <br/>
                    <div>
                      <a href="/nosotros" className="oblique-button">
                        Conocer más
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className='banner-bg-ques' md={7} align='center'>
              <img src={doc} className='doc-style' />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="banner-bg-exp" align="rigth">
        <Container>
          <Row >
            <Col md={4}> 
              <div className="banner-title-exp">Expertos en:</div><br/>
              <div>
                <a href="/servicios" className="oblique-button">
                  Todos nuestros servicios
                </a>
              </div>
            </Col>
            <Col  md={2} xs={ 6 }>
              <a href='/hope'><img src={hope} className="banner-img"></img></a>
              <div className='service-text'>Ortopedia dental</div>
            </Col>
            <Col md={2} xs={ 6 }>
              <a href='/ortodoncia'><img src={orto} className="banner-img"></img></a>
              <div className='service-text'>Ortodoncia sin límites</div>
            </Col>
            <Col md={2} xs={ 6 }>
              <a href='/blanqueamiento'><img src={clean} className="banner-img"></img></a>
              <div className='service-text'>Blanqueamiento dental</div>
            </Col>
            <Col md={2} xs={ 6 }>
              <a href='/airflow'><img src={gbt} className="banner-img"></img></a>
              <div className='service-text'>Limpieza de última generación</div>
              <div className='service-text-small'>(Air Flow)</div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="banner-bg-vis" align="center">
      
      </div>
      <div className="clearfixe-body"></div>
      <div className='banner-container-ques'>
      <Container>
          <div className="banner-text-container">
            <div className="banner-title-ques">Nuestro <span className='banner-title-equi-2'> equipazo</span><div className='banner-title-equi-2'> de expertos</div></div>      
          </div>    
        </Container>
        <div style={{ height: '70px' }}></div>
        <Container>
          <Row>
            <Col md={4} className='equipo-trabajo' >
              <div ref={ refAlberto }>
              {hoveringAlberto ? <img src={alberto_a} className='img-colab' /> : <img src={alberto} className='img-colab' /> }
              </div>
              <br/>
              <div className='equipo-trabajo-main'>Dr. Alberto Aguilar </div>
              {/* <div className='text-colab'>Cirugia dental </div>*/}
              <br/> 
              <div className='equipo-trabajo-principal-text'>
                Hola, soy el Dr. Alberto Aguilar, odontólogo y especialista en Ortodoncia egresado de UNITEC. Desde hace más de 25 años fundé D-Natural con el objetivo de brindar a mis pacientes una buena salud dental, con el fin de que expresen en su vida la mejor de sus sonrisas. Me encanta ofrecer a mis pacientes lo mejor en calidad y calidez. Mi pasión es pasar tiempo con mi familia, meditar y realizar deportes al aire libre.
              </div>
            </Col>
            <Col md={8}>
            <Container>
                <Row className='equipo-trabajo'>

                  { doctores.map( item => (
                    <Col md={4} xs={ 6 } ref={ item.ref } onClick={ () => opendoctor(item.id) } style={{ cursor: 'pointer' }}>
                      {item.hover ? <img src={item.img_b} className='img-colab'/> : <img src={item.img} className='img-colab'/>}
                      
                      <div>{ item.title }</div>
                    </Col>
                  ) ) }
                              
                  {/* <Col md={4} onClick={ () => opendoctor(item.id) }>
                    <img src={doc4} className='img-colab'/>
                    <div>Dra. Gabriela</div>
                  </Col>
                  <Col md={4}>
                    <img src={doc5} className='img-colab' />
                    <div>Dr. Martín Castellanos</div>
                  </Col>
                  <Col md={4}>
                  <img src={doc6} className='img-colab' />
                    <div>Dra. Karent Pére</div>
                  </Col>

                  <Col md={4}>
                    <img src={doc4} className='img-colab' />
                    <div>Dra. Adriana Ibarra</div>
                  </Col>
                  <Col md={4}>
                    <img src={doc6} className='img-colab' />
                    <div>Dra. Cristina Olive</div>
                  </Col>
                  <Col md={4}>
                    <img src={doc7} className='img-colab' />
                    <div>Dr. Gabriel Pérez</div>
                  </Col>

                  <Col md={ 4 }></Col>
                  <Col md={4}>
                    <img src={doc6} className='img-colab'/>
                    <div>Dr. Gabriel Pérez</div>
                  </Col>
                  <Col md={4}>
                    <img src={doc7} className='img-colab'/>
                    <div>Dr. Gabriel Pérez</div>
                  </Col> */}
                         
                </Row>

              </Container>
              </Col>
          </Row>
        </Container>

      </div>
      <div className="clearfixe-body"></div>
      <div>
        <Container>
          <div>
            <Row>
              <Col md={5} style={{ position: 'relative' }}>
               <div className='banner-title-sm' >Luce una <span style={{ color: '#a7a7a5' }} >sonrisa perfecta</span></div>
                <div className='flecha-div'>
                  <img className='flecha-img' src={flecha} />
                </div>
                { isMobile && <div className="clearfixe-body"></div> }
              </Col>
              <Col md={7}>
                <Carousel>
                  { testimonios.map( item => (
                    <Carousel.Item>
                      <div className="carousel-slide">
                        <div class="card testimonio" >
                            <center><img src={item.img}  class="card-img-top img-test" alt="..."/></center>
                            <div class="card-body" align="center">
                              <h5 class="card-title">{ item.title }</h5>
                              <p class="card-text">{ item.desc }</p>
                            </div>
                        </div>
                    </div>
                    </Carousel.Item>
                  ))}
                  
                  { /*  <Carousel.Item>
                  <div className="carousel-slide">
                   
                  </div>
                  </Carousel.Item>*/}
        {/* Añade más items de Carousel.Item para más tarjetas */}
                </Carousel>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      { !isMobile && <div className="clearfixe-body"></div> }
      
      <div className='container-default'>
        <Container>
          <div className='banner-title-bg'>Blog</div>
          <div className="clearfixe-body"></div>
          <div>
            <Row>
              {articlesList.slice(0, 3).map( item => (
                <Col md={ 4 } xs={ 12 } className='col-md-custom article-item'>
                  <div class="card card-blog" >
                    <img src={ item.img } class="card-img-top" alt="..."/>
                    <div class="card-body">
                    <h5 class="card-title">{ item.title }</h5>
                    <p class="card-text">{ item.desc }</p>
                    <a href={ item.url } className='btn btn-primary more-text'>Leer más</a>
                    </div>
                  </div>    
                </Col>
              ))}
            </Row>
          </div>
          <div className="clearfixe-body"></div>
          <div align='center'>
          <a href="blog" className="oblique-button">Más Entradas </a>
          </div>
        </Container>
        <div className="clearfixe-body"></div>
      </div>

      <Container>
        <div className="banner-title-vis" align="center">¡Visítanos ahora!</div>
        <br/>
      <Slider {...settings}>
        <div>
          <img src={consul1} className='img-carr' alt="Imagen 1" />
        </div>
        <div>
          <img src={consul2} className='img-carr' alt="Imagen 2" />
        </div>
        <div>
          <img src={consul3} className='img-carr' alt="Imagen 3" />
        </div>
        <div>
          <img src={consul4} className='img-carr' alt="Imagen 4" />
        </div>
        <div>
          <img src={consul5} className='img-carr' alt="Imagen 5" />
        </div>
        <div>
          <img src={consul6} className='img-carr' alt="Imagen 6" />
        </div>
        <div>
          <img src={consul7} className='img-carr' alt="Imagen 6" />
        </div>
        <div>
          <img src={consul8} className='img-carr' alt="Imagen 6" />
        </div>
        <div>
          <img src={consul9} className='img-carr' alt="Imagen 6" />
        </div>
        <div>
          <img src={consul10} className='img-carr' alt="Imagen 6" />
        </div>
        <div>
          <img src={consul11} className='img-carr' alt="Imagen 6" />
        </div>
        <div>
          <img src={consul12} className='img-carr' alt="Imagen 6" />
        </div>
        <div>
          <img src={consul13} className='img-carr' alt="Imagen 6" />
        </div>
        <div>
          <img src={consul14} className='img-carr' alt="Imagen 6" />
        </div>
        <div>
          <img src={consul15} className='img-carr' alt="Imagen 6" />
        </div>
        <div>
          <img src={consul16} className='img-carr' alt="Imagen 6" />
        </div>
        <div>
          <img src={consul17} className='img-carr' alt="Imagen 6" />
        </div>
        <div>
          <img src={consul18} className='img-carr' alt="Imagen 6" />
        </div>
      </Slider>
    </Container>
    { !isMobile && <div className="clearfixe-body"></div> }
    { !isMobile && <div className="clearfixe-body"></div> }
    
      <div className='container-default'>
        <Container>
          <div className='banner-title-sm-bold' align="center" >Clínicas</div>
          <div className="clearfixe-body"></div>
          <Tabs defaultActiveKey="satelite" id="uncontrolled-tab-example" className="justify-content-center" >
            <Tab eventKey="satelite" title="Satélite" >
              <Container>
                <Row className="align-items-center">
                  <Col md={ 5 }>
                    { isMobile && <br/> }
                    <div className='text-gust'>¡Te estamos <span className='banner-title-address-2'>esperando! </span></div>
                    <br/>
                    <div className='text-address'>Cto. Científicos #28 1er piso<br/>
                      Cd Satélite C.P. 53100 <br/>
                      Naucalpan, Edo. Mex.
                    </div>
                    <br/>

                    <div>
                      <div className='phones-contact-text'>
                        <TelephoneFill className='phones-contact' ></TelephoneFill>&nbsp;&nbsp; <a href='tel:5555722119' style={{ color: '#000' }}> (55) 5572 2119 / 20 </a>
                      </div>

                      <div className='phones-contact-text'>
                        <Whatsapp className='phones-contact'></Whatsapp>&nbsp;&nbsp; <a href='https://wa.me/5215561728112' style={{ color: '#000' }}> (55) 6172 8112 </a>
                      </div>
                    </div>

                    <br/>
                    <div style={{color:'#00a6e7'}}className='phones-contact-text-2'>
                      <b>HORARIO</b><br/>
                      Lunes a viernes 9 am - 8 pm <br/>
                      Sábados 9 am - 2 pm
                    </div>
                  </Col>

                  <Col md={ 7 }>
                  <div>
                    <div className="clearfixe-body"></div>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d887.3917804126144!2d-99.22963987022416!3d19.514326386185953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sCto.%20Cient%C3%ADficos%20%2328%201er%20piso%20Cd%20Sat%C3%A9lite%20C.P.%2053100%20Naucalpan%2C%20Edo.%20Mex.!5e0!3m2!1ses-419!2smx!4v1685497840591!5m2!1ses-419!2smx"
                        width={ isMobile ? '100%' : "600" } height={ isMobile ? '250' : "450" } allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="anzures" title="Anzures">
              <Container>
                <Row className="align-items-center">
                  <Col md={ 5 }>
                    { isMobile && <br/> }
                    <div className='text-gust'>¡Te estamos <span className='banner-title-address-2'>esperando! </span></div>
                    <br/>
                    <div className='text-address'>Gauss #12 6to piso<br/>
                      Col. Anzures C.P. 11590<br/>
                      CDMX
                    </div>
                    <br/>

                    <div>
                      <div className='phones-contact-text'>
                        <TelephoneFill className='phones-contact' ></TelephoneFill>&nbsp;&nbsp; <a href='tel:5552500850' style={{ color: '#000' }}>(55) 5250 0850</a>
                      </div>

                      <div className='phones-contact-text'>
                        <Whatsapp className='phones-contact'></Whatsapp>&nbsp;&nbsp; <a href="https://wa.me/5215544990591" style={{ color: '#000' }}>(55) 4499 0591</a>
                      </div>
                    </div>

                    <br/>
                    <div style={{color:'#00a6e7'}}className='phones-contact-text-2'>
                      <b>HORARIO</b><br/>
                      Lunes a viernes 9 am - 8 pm <br/>
                      Sábados 9 am - 2 pm
                    </div>
                  </Col>
                  <Col md={ 7 }>
                    <div>
                      <div className="clearfixe-body">
                      </div>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d940.6111947061551!2d-99.18321737152132!3d19.43638116353808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f8ac24134035%3A0x987b7403c3b2f3a6!2sGauss%2012%2C%20Anzures%2C%20Miguel%20Hidalgo%2C%2011590%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1686447146971!5m2!1ses-419!2smx"
                      width={ isMobile ? '100%' : "600" } height={ isMobile ? '250' : "450" } allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Tab>
          </Tabs>    
        </Container>
      </div>
        
        </>
    );

}
export default Home;
