import { isMobile } from 'react-device-detect';
import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';

import odonto from '../img/odontologia.png'
import ortodon from '../img/ortodoncialist.png'
import circulo from '../img/circulo.png'
import diente from '../img/diente.png'
function Endodoncia() {
    return (
        <>
             <div>
                <Container className='container-no-format'>
                    <div>
                        <div><img src={odonto } className='image-100'/></div>
                    </div>        
                </Container> 
                <Container className='container-default'>
                <Row>
                            <Col><div className="banner-title-bg">Endodoncia</div>
                            </Col>
                </Row>
                <br/>
                <Row>
                    <div align="center">
                        <div className="banner-descrip-orto">
                        ¡Salva tus dientes!
                        </div>
                    </div>
                    { !isMobile && <div className="clearfixe-body"></div> }
                </Row>
                </Container>
                    <div>
                        <Container>
                            <Row className='align-items-center'>
                                <Col md={ 5 }>
                            <div className='font-blond-small'> Acude con nosotros si presentas los
                                siguientes síntomas, ya sean juntos
                                o separados.</div>
                                <br/>
                                <div>
                                    <img src={circulo } className='circle'/>&nbsp;&nbsp;Dolor Dental Espontáneo a estímulos
                                    o pulsátil<br/><br/>
                                    <img src={circulo } className='circle'/>&nbsp;&nbsp;Movilidad dental<br/><br/>
                                    <img src={circulo } className='circle'/>&nbsp;&nbsp;Sensibilidad a cambios térmicos como
                                    calor o frío<br/><br/>
                                    <img src={circulo } className='circle'/>&nbsp;&nbsp;Abscesos (fístula dental)<br/><br/>
                                    <img src={circulo } className='circle'/>&nbsp;&nbsp;Sensibilidad al tocarlo<br/><br/>
                                    <img src={circulo } className='circle'/>&nbsp;&nbsp;Decoloración del diente por golpe<br/><br/>
                                </div>
                                </Col>
                                <Col md={ 7 }>
                                    <div><img src={ ortodon} className='img-alin'/></div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                <div className="clearfixe-body"></div>
                <div className='comp-background container-default'>
                    <Container>
                        <Row className='align-items-center'>
                        <Col md={ 1 }>
                            <div className='diente'>
                                <img src={diente} className='img-100 no-show'/>
                            </div>
                        </Col>
                            <Col md={ 3 }>
                                <div>
                                    
                                    El proceso se realiza en una sesión, no es
                                    molesta y el postoperatorio es exitoso.
                                </div>
                            </Col>
                            <Col md={ 4 }>
                            Después de realizar la endodoncia, es
                            importante reconstruir el diente para
                            que adquiera nuevamente su fuerza,
                            función y anatomía.
                            </Col>
                            <Col md={ 4 }>
                            Es de vital importancia que el paciente
                            regrese a la clínica a terminar el proceso,
                            de lo contrario ese diente podría
                            fracturarse con facilidad.
                            </Col>
                        </Row>
                    </Container>
                </div>
                
                </div>
                <div className="clearfixe-body"></div>
        </>
    );

}

export default Endodoncia;