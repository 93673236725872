import { useState } from 'react';
import { isMobile } from 'react-device-detect';

import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import PhotoAlbum from "react-photo-album";

import gbt from '../img/gbts.png'
import pediatria from '../img/pediatria.png'
import prev from '../img/prev.png'
import orto from '../img/ortodoncia.png'
import este from '../img/estetica.png'
import blanq from '../img/bannerblanque.png'
import Blanqueamiento from '../pages/Blanqueamiento'
import Home from '../pages/Home'

//const PATH = 'http://localhost:3000'
const PATH = 'https://www.d-natural.mx/'

const photosA = [
    {
        src: PATH + '/services/airflow.png',
        width: 1739,
        height: 502,
        path: "/airflow"
    }
]

const photosB = [
    {
        src: PATH + '/services/pediatria.png',
        width: 634,
        height: 220,
        path: "/ortopediatria"
    },
    {
        src: PATH + '/services/estetica.png',
        width: 634,
        height: 444,
        path: "/estetica"
    },
    {
        src: PATH + '/services/endodoncia.png',
        width: 634,
        height: 214,
        path: "/endodoncia"
    },
    {
        src: PATH + '/services/bannerblanque.png',
        width: 634,
        height: 444,
        path: "/blanqueamiento"
    },
    {
        src: PATH + '/services/prev.png',
        width: 550,
        height: 442,
        path: "/odontologiaprev"
    },
    {
        src: PATH + '/services/cirugia.png',
        width: 550,
        height: 543,
        path: "/cirugia"
    },
    {
        src: PATH + '/services/hope.png',
        width: 550,
        height: 336,
        path: "/hope"
    },
    {
        src: PATH + '/services/ortodoncia.png',
        width: 547,
        height: 667,
        path: "/ortodoncia"
    },
    {
        src: PATH + '/services/protesis.png',
        width: 547,
        height: 220,
        path: "/protesis"
    },
    {
        src: PATH + '/services/implantes.png',
        width: 547,
        height: 444,
        path: "/implantes"
    }
    
];

const goToServiceA = (e) => {
    console.log("e", e.photo.path)
    window.location.href = e.photo.path
}

const goToServiceB = (e) => {
    console.log("e", e.photo.path)
    window.location.href = e.photo.path
}

function Servicios() {
    return (
        <>
   
            <div className="clearfixe-body"></div>
            <div>
                <Container>
                    <Row>
                        <Col><div className="banner-title-bg">Nuestros Servicios</div>
                        </Col>
                    </Row>
                    <br/>
                    <br/>

                    <Row>
                        <PhotoAlbum layout="rows" photos={photosA} onClick={ goToServiceA }
                            renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                                <div className='photo-class' style={{ position: "relative", ...wrapperStyle }}>
                                    { renderDefaultPhoto({ wrapped: true }) }
                                </div>
                            )}
                        />
                    </Row>
                    <div style={{ marginBottom: '15px' }} />
                    <Row>
                        <PhotoAlbum layout="columns" photos={photosB} onClick={ goToServiceB } columns={ isMobile ? 2 : 3 }
                            renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                                <div className='photo-class' style={{ position: "relative", ...wrapperStyle }}>
                                    { renderDefaultPhoto({ wrapped: true }) }
                                </div>
                            )}
                        />
                    </Row>
                    
                </Container>
                <div className="clearfixe-body"></div>
            </div>
        </>
    );
}
 export default Servicios;