import { useState, useEffect, useRef } from 'react'
import {Helmet} from "react-helmet";
import { useHover } from "@uidotdev/usehooks";
import { isMobile } from 'react-device-detect';
import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';

import paciente from '../img/paciente.png'

function Hope() { 
    return (<> 
        <Helmet>
            <script> 
                {`gtag('event', 'conversion', {'send_to': 'AW-10875000575/yBEKCPetr7IDEP-tzcEo'});`}
            </script>
        </Helmet>

        <Container className='container-default'>
            <Row>
                <Col>
                    <div className="banner-title-ques-2" style={{ textAlign: 'center' }}>¡Gracias!</div>
                    <div className="service-text">Tu mensaje ha sido enviado. En breve nos comunicaremos contigo.</div>
                </Col>
            </Row>
        </Container>
        <div className="clearfixe-body"></div>
        <Container className='container-no-format'>
            <div>
                <div><img src={paciente } className='image-100'/></div>
            </div>        
        </Container>
    </>);
}
export default Hope;