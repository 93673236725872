import { useHover } from "@uidotdev/usehooks";
import { isMobile } from 'react-device-detect';
import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';

import bracketini from '../img/bracketini.png'
import bracketini_b from '../img/bracketini_b.png'
import bracketlig from '../img/bracketsliga.png'
import { AlignCenter, Whatsapp } from 'react-bootstrap-icons';
import bracketcera from '../img/bracketce.png'
import bracketsceramicos from '../img/bracketsceramicos.png'
import bracketbla from '../img/bracketsblan.png'
import alineadores from '../img/alineadores.png'
function Ortodoncia() {
    const [ref, hovering] = useHover();

    return (
        <>
            
            <div>
                <Container className='container-no-format'> 
                    <div ref={ ref }>
                        { hovering ? <img src={ bracketini_b } className='image-100'></img> : <img src={ bracketini } className='image-100'></img> }
                    </div>
                </Container>
            </div>
            <div className='container-default'>
                <Container>
                <Row>
                        <Col><div className="banner-title-bg">Ortodoncia sin límites</div>
                        </Col>
                </Row>
                <br/>
                <Row>
                    <div align="center">
                        <div className="banner-descrip-orto">
                            Contamos con los sistemas más avanzados, diseñados y perfeccionados
                            para que elijas la opción que mejor se adapte a tus gustos y necesidades.
                        </div>
                    </div>
                </Row>
                <br/>
                <Row className='align-items-center'>
                        <Col md={ 5 }  xs={ 12 } className='banner-bg-orto p-ortodoncia' align='center'>
                            <div className='banner-bg-orto'> <img src={bracketcera} className='banner-orto-img' /></div>
                            <br/>
                            <div className='font-orto'>
                                <br/>
                                <div className='font-blond'>BRACKETS DE AUTO LIGADO</div><br/>
                                <div className='font-desc'>
                                    Nuestra tecnología con brackets de autoligado, brinda un tratamiento con mayor control, menor tiempo y más comodidad para ti. Posicionan los dientes de forma más natural, usando fuerzas más sutiles.
                                </div>
                            </div>
                        </Col>        
                        <Col md={ 7 }  xs={ 12 } className='p-ortodoncia' align='center' >
                            <Container>
                            <Row className='align-items-center p-2 pb-4'>
                                <Col md={ 4 } xs={ 12 }>
                                    <div><img src={bracketlig} className='img-serv-1' /></div>
                                </Col>
                                <Col md={ 8 } xs={ 12 }>
                                    <div className='font-orto' style={{ textAlign: 'left' }}>
                                        <span className='font-blond' style={{ textTransform: 'uppercase' }}> Brackets de autoligado Metálico</span><br/>
                                        <div className='font-desc'>Su material y diseño, nos permite tener menor fricción y molestias en nuestros pacientes, mejor acceso en la limpieza dental. Ideal para niños y adolescentes.</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='align-items-center p-2 pb-4'>
                                <Col md={ 4 } xs={ 12 }>
                                    <div><img src={bracketsceramicos} className='img-serv-1' /></div>
                                </Col>
                                <Col md={ 8 } xs={ 12 }>
                                    <div className='font-orto' style={{ textAlign: 'left' }}>
                                        <span className='font-blond' style={{ textTransform: 'uppercase' }}>Brackets de auto ligado cerámicos</span>
                                        <div className='font-desc'>
                                            Están hecho de material transparente, lo que hace que sean casi imperceptibles, son ideales para los pacientes que buscan discreción en su tratamiento. 
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='align-items-center p-2'>
                                <Col md={ 4 } xs={ 12 }>
                                    <div><img src={bracketbla} className='img-serv-1' /></div>
                                </Col>
                                <Col md={ 8 } xs={ 12 }>
                                    <div className='font-orto' style={{ textAlign: 'left' }}>
                                        <span className='font-blond' style={{ textTransform: 'uppercase' }}>Brackets de zafiro</span>
                                        <div className='font-desc'>
                                            Conoce los brackets ¡más cristalinos que existen! Ideal para pacientes que buscan alta estética y discreción absoluta.
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            </Container>
                        </Col>
                </Row>    
                </Container>
                <div className='container-default comp-background' style={ !isMobile ? { paddingBottom: 0 } : {} }>
                    <Container>
                        <div>
                        <Row>
                            <Col><div className="banner-title-bg" style={{ textAlign: 'left' }}>Alineadores inteligentes</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={ 6 } className='p-ortodoncia' >
                                <div className='font-orto'>

                                <div className='font-desc-regular' style={{ textAlign: 'left' }}>
                                
                                    Vive el surgimiento de una nueva forma de alinear tus
                                    dientes, de la manera más estética, cómoda y práctica.
                                    <br/><br/>
                            A través de tecnología de escaneo digital e imagen
                            avanzada 3D, los alineadores dentales se han
                                    convertido en la opción más solicitada a nivel mundial.
                                </div>
                                </div>
                                <br/>
                                <div style={{ position: 'relative' }}><img src={alineadores} className='img-alin-absolute'></img></div>
                            </Col>
                            <Col md={ 6 } className='p-ortodoncia' >
                                    <div className='font-desc' style={{ fontWeight: 'bold' }}>
                                        <b>Consigue una sonrisa perfecta
                                        siguiendo estos sencillos pasos: </b>
                                    </div>
    
                                    <br/>
                                    <span className='font-blond-small'><span className='circle'>1</span>&nbsp;&nbsp;&nbsp;DIAGNÓSTICO.</span>
                                    <br/>
                                    <div className='font-desc'>
                                    Te ayudaremos a elegir el mejor tratamiento
                                    para ti.
                                    </div>
                                    <br/>
                                    <span className='font-blond-small'><span className='circle'>2</span>&nbsp;&nbsp;&nbsp;ESCANEO.</span>
                                    <br/>
                                    <div className='font-desc'>
                                    Realizamos un escaneo digital de tus
                                    dientes, para darle exactitud y precisión a tu
                                    tratamiento. En 3D podrás ver una
                                        proyección de cómo quedarán tus dientes.
                                    </div>
                                    <br/>
                                    <span className='font-blond-small'><span className='circle'>3</span>&nbsp;&nbsp;&nbsp;ALINEADORES.</span>
                                    <br/>
                                    <div className='font-desc'>
                                    Elaboraremos los alineadores para que inicies cuanto antes y comiences a ser tú mismo.
                                    </div>
                                    <br/>
                                    <span className='font-blond-small'><span className='circle'>4</span>&nbsp;&nbsp;&nbsp;TE ACOMPAÑAMOS.</span>
                                    <br/>
                                    <div className='font-desc'>
                                    Durante todo el procedimiento estaremos
                                        siempre a tu lado.
                                    </div>
                                        
                            </Col>
                        </Row>
                        </div>
                    </Container>
                </div>

                <div className='container-default'>
                <Container>
                    <Row className='banner-bg-coti'>
                            <Col className='column-fina' md={ 6 } xs={ 12 }>
                                <div className='line-fina'>
                                <div className='title-finan'><span className='font-blond title-finan'>Cita de valoración</span></div><br/>
                                <div>Agenda en D-Natural una cita de valoración, para que puedas conocer al equipo de doctores expertos en crear sonrisas, nuestras instalaciones y tecnología. Así como nuestros planes de financiamiento. 
                                    <br/><br/>
                                    También conoceremos tus antecedentes médicos, historia clínica y escucharemos tus necesidades y expectativas, de esta manera podremos ofrecerte un diagnóstico y un plan de tratamiento personalizado cuidando tu seguridad y satisfacción personal.</div>
                                </div>
                                
                            </Col>
                            <Col className='column-fina' md={ 6 } xs={ 12 }>
                                <div >
                                <div className='title-finan'><span className='font-blond title-finan'>Planes de pago</span></div><br/>
                                    En D-Natural sabemos lo importante no solo de crear sonrisas saludables y sorprendentes, sino también hacer que eso suceda, y con ese fin hemos creado un plan de financiamiento exclusivo para ortodoncia con opciones flexibles de pago inicial y pago mensual, ofreciendo a nuestros pacientes 0% de intereses.
                                    <br/><br/>
                                    ¡Nos enorgullece encontrar opciones de pago cómodas y accesibles para satisfacer las necesidades de todas y cada una de las sonrisas que creamos!

                                </div>
                            </Col>
                            <Row className='align-items-center'>
                                <Col md={2}></Col>
                                <Col md={8} className='cita-fina font-blond'>
                                    <a href="https://wa.me/5215561728112" target='_blank'><Whatsapp />&nbsp;&nbsp;<span style={{ textTransform: 'uppercase' }}>Agenda tu cita hoy mismo</span> </a>
                                </Col>
                                <Col  md={2}></Col>
                            </Row>
                        </Row>
                        
                </Container>
                </div>
            </div>
        </>
    );
}
export default Ortodoncia;