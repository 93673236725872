import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';

import implantes from '../img/implantes.png'
import implandent from '../img/implantesdien.png'

function Implantes() {
    return (
        <>
            <div>
            <Container className='container-no-format'>
                    <div>
                        <div><img src={implantes } className='image-100'/></div>
                    </div>        
                </Container> 
                <Container className='container-default'>
                <Row>
                            <Col><div className="banner-title-bg">Implantes</div>
                            </Col>
                </Row>
                <div className="clearfixe-body"></div>
                    <Row>
                        <div align="center">
                            <div className="banner-descrip-orto">
                                La opción ideal para sustituir un diente faltante
                            </div>
                        </div>
                </Row>
                </Container>
                <div className="clearfixe-body"></div>
                <div>
                    <Container>
                        <Row className='align-items-center format-implant'>
                            <Col md={5}>
                                <div><img src={implandent} className="img-100" /></div>
                            </Col>
                            <Col md={7}>
                            <span className='font-blond'>IMPLANTES</span><br/><br/>

                            Siéntete seguro, joven y naturalmente sano.
                            Los implantes dentales son la mejor opción para permitirte volver a
                            sentir los dientes tan naturales como los tuyos. Al tener 99.8% de
                            compatibilidad con nuestro cuerpo, el titanio es el material ideal
                            para realizar implantes dentales resistentes.

                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="clearfixe-body"></div>
                </div>
        
        </>

    );
}
export default Implantes;   