import { useHover } from "@uidotdev/usehooks";
import { isMobile } from 'react-device-detect';
import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';

import hope from '../img/hopepost.png'
import hope_b from '../img/hopepost_b.png'
import hopechi from '../img/hopechild.png'
import hopelog from '../img/hopelogo.png'
import hop from '../img/HOP.png'

function Hope() { 
    const [ref, hovering] = useHover();

    return (<>
    
            <div>
            
            <Container className='container-no-format'>
                <div ref={ ref }>
                    { hovering ? <img src={ hope_b } className='image-100'/> : <img src={ hope } className='image-100'/> }
                </div>        
            </Container> 

            <Container className='container-default'>
            <Row>
                <Col><div className="banner-title-bg">¿Qué es la Ortopedia Dental?</div>
                </Col>
            </Row>
            <br/>
            <Row>
                <div align="center">
                    <div className="banner-descrip-orto">
                        La ortopedia tiene como objetivo, compensar el crecimiento de los huesos de la boca, a través del uso de aparatos fijos o removibles.
                    </div>
                </div>
            </Row>
            </Container>
            { !isMobile && <div className="clearfixe-body"></div> }
            <Container>
                <Row>
                    <Col md={5} className='p-hope'>
                    <div className='font-orto' style={{ textAlign: 'left' }}>
                        <span className='font-blond'>¿Cuándo se inicia un tratamiento de Ortopedia?</span><br/>

                        <div className='font-desc'>
                            Está indicado en niños entre los 6 y 9 años de edad. Un tratamiento temprano evita maloclusiones en la dentición permanente.
                        </div>
                        <br />

                        <span className='font-blond'>¿Cuáles son los beneficios de realizar un tratamiento de Ortopedia?</span><br/><br/>

                        <div className='font-desc'>
                        •	Favorece el crecimiento correcto de los huesos de la cara<br/>
                        •	Corrige malos hábitos<br/>
                        •	Favorece la higiene (los dientes alineados se limpian mejor)<br/>
                        •	Crea espacio para los dientes que están por salir<br/>
                        •	Reduce y elimina problemas de respiración<br/>
                        •	Reduce la posibilidad de extracciones en un tratamiento de ortodoncia futuro<br/>
                        •	Mantiene una correcta relación entre la mandíbula y el maxilar<br/>
                        •	Mejora la autoestima del niño
                        </div>
                        <br />

                        <span className='phones-contact-text'>En D-Natural desarrollamos un programa denominado HOPE, el cuál contribuirá para que nuestros pacientes crezcan en todos los sentidos y expresen lo mejor de ellos.</span>
                    </div>
                    </Col>
                    <Col md={7}>
                        <div style={{ position: 'relative' }} align="center"><img src={hopechi} className='img-80'/></div>
                    </Col>
                </Row>
                <Row>
                    <div><img src={hopelog} className='img-100'/></div>
                </Row>
                <br/>
                <Row>
                    <div align="center">
                        <div className='banner-descrip-orto font-aling-center' >¡CONOCE MÁS SOBRE <img src={hop}/> Y PERMITE A TU HIJO DESARROLLAR TODO SU POTENCIAL!</div>
                    </div>
                </Row>
            </Container>
            <div className="clearfixe-body"></div>
        </div>
        
    
    
    
    </>);
}
export default Hope;