import { useHover } from "@uidotdev/usehooks";
import { isMobile } from 'react-device-detect';

import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';

import ortopedini from '../img/ortopedini.png'
import ortopedini_b from '../img/ortopedini_b.png'
import hada from '../img/hada.png'
import limpieza from '../img/limpieza.png'
import flour from '../img/flour.png'
import selladores from '../img/selladores.png'

import ortopedia from '../img/ortopediades.png'
import pulpotomia from '../img/pulpotomia.png'
import pulpectomia from '../img/pulpectomia.png'
import celmadre from '../img/celmadre.png'

function Ortopediatria() { 
    const [ref, hovering] = useHover();

    return (
        <>
            <div>
                <Container className='container-no-format'>
                    <div ref={ ref }>
                        { hovering ? <img src={ ortopedini_b } className='image-100'/> : <img src={ ortopedini } className='image-100'/> }
                    </div>        
                </Container> 
                <div>
                <Container className='container-default'>
                <Row>
                    <Col><div className={ isMobile ? 'banner-title-bg-mobile' : "banner-title-bg" }>Odontopediatría</div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <div align="center">
                        <div className="banner-descrip-orto">
                            Hacemos de su primera visita al dentista una experiencia inolvidable.
                        </div>
                    </div>
                    </Row>                    
                    </Container>
                </div>
                <div className="clearfixe-body"></div>
                <div>
                    <Container>
                        <Row>
                            <Col md={ 3 } className='format-bg-ortoped'>
                                <div className="p-3">
                                    <div className='hada'>
                                        <img src={hada} className='padding-img img-alin no-show' />
                                    </div>
                                    <div className='align-items-center'>
                                        <div className='formt-hada'>
                                        MAX Y EL HADA DE
                                        LOS DIENTES VAN DE
                                        LA MANO CONTIGO
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={ 3 }>

                            <Row className='align-items-center'>
                                <div>
                                <img src={limpieza}  className='padding-img img-alin'/></div>
                            </Row>
                            <Row className='align-items-center format-ortopediatria'>
                                <samp className='font-blond-small'> Limpieza Dental</samp>
                                <div className='font-desc'>
                                La limpieza dental ultrasónica
                                es una tecnología avanzada,
                                moderna y segura, utilizada
                                para la remoción de sarro, placa
                                dentobacteriana y manchas.
                                Una técnica súper cómoda para
                                nuestros pequeños pacientes.
                                </div>
                            </Row>
                            </Col>
                            <Col md={ 3 }>
                            <Row className='align-items-center'>
                                <div>
                                <img src={flour}  className='padding-img img-alin'/></div>
                            </Row>
                            <Row className='align-items-center format-ortopediatria'>
                                <span className='font-blond-small'>Flúor</span>
                                <div className='font-desc'>
                                Un sistema preventivo contra
                                la caries muy efectivo, ya que
                                hace más resistente el esmalte
                                de los dientes, elimina las
                                bacterias que producen la
                                caries dental.
                                </div>
                            </Row>
                            </Col>
                            <Col md={ 3 }>
                            <Row className='align-items-center format-ortopediatria'>
                                <div>
                                <img src={selladores}  className='padding-img img-alin'/></div>
                            </Row>
                            <Row className='align-items-center format-ortopediatria'>
                                <span className='font-blond-small'>Selladores</span>
                                <div className='font-desc'>
                                Son recubrimientos a base de
                                resina adhesiva que se coloca
                                sobre los molares o
                                premolares. Evitan la entrada
                                de comida y gérmenes que
                                promueven la caries.
                                </div>

                            </Row>
                            </Col>    
                        </Row>
                        <div className="clearfixe-body"></div>
                        <Row>
                            <Col md={ 3 }>
                                <Row className='align-items-center format-ortopediatria'>
                                    <div>
                                        <img src={ortopedia} className='padding-img img-alin' />
                                    </div>
                                </Row>
                                <Row className='align-items-center format-ortopediatria'>
                                    <samp className='font-blond-small'>Ortopedia</samp>
                                    <div className='font-desc'>
                                    Tratamiento ideal para niños de
                                    6 a 9 años donde empleamos
                                    aparatos ortopédicos dentales
                                    para estimular el crecimiento de
                                    los huesos de los maxilares.
                                    </div>
                                </Row>
                                </Col>
                                <Col md={ 3 }>
                                <Row className='align-items-center format-ortopediatria'>
                                    <div>
                                        <img src={pulpotomia}  className='padding-img img-alin'/>
                                    </div>
                                </Row>
                                <Row className='align-items-center format-ortopediatria'>
                                    <samp className='font-blond-small'> Pulpotomía</samp>
                                    <div className='font-desc'>
                                    En este tipo de tratamientos se
                                    quita una parte del nervio
                                    (pulpa) con el fin de poder
                                    colocar un medicamento
                                    después de esto se hace una
                                    corona.
                                    </div>
                                </Row>
                                </Col>
                                <Col md={ 3 }>
                                <Row className='align-items-center format-ortopediatria'>
                                    <div>
                                        <img src={pulpectomia}  className='padding-img img-alin'/>
                                    </div>
                                </Row>
                                <Row className='align-items-center format-ortopediatria'>
                                    <span className='font-blond-small'>Pulpectomia</span>
                                        <div className='font-desc'>
                                        A diferencia de la pulpotomía
                                        en este tipo de tratamientos se
                                        remueve toda la pulpa del
                                        diente (nervio) debido a que se
                                        infectó. Se lava el conducto y
                                        se coloca un medicamento y
                                        después se coloca una corona.
                                        </div>
                                </Row>
                                </Col>
                                <Col md={ 3 }>
                                <Row className='align-items-center format-ortopediatria'>
                                    <div>
                                        <img src={celmadre}  className='padding-img img-alin'/>
                                    </div>
                                </Row>
                                <Row className='align-items-center format-ortopediatria'>
                                    <span className='font-blond-small'>Células Madre Dentales</span>
                                        <div className='font-desc'>
                                        Los dientes son una fuente
                                        abundante de Células Madre que
                                        se pueden preservar para el futuro
                                        obteniendo un seguro biológico
                                        para el paciente y su familia.
                                        Contamos con el servicio de
                                        recolección de dientes para
                                        enviarlos a través de BioEDEN al
                                        banco de células dentales, en
                                        donde se crío-preservarán.
                                        </div>
                                </Row>
                                </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="clearfixe-body"></div>
        </>
    );

}
export default Ortopediatria;