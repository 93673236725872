import { FloatingWhatsApp } from 'react-floating-whatsapp'

import logo from './logo.png';
import './App.css';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal'
import { Navbar, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Facebook, Instagram,  Phone,  Telephone,  TelephoneFill,  Tiktok, Whatsapp } from 'react-bootstrap-icons';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Carousel } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home'
import Nosotros from './pages/Nosotros'
import hop from './img/HOP.png'
import orto from './img/ortosl.png'
import hope from './img/hope.png'
import clean from './img/blanq.png'
import gbt from './img/gbt.png'
import consul1 from './img/imagen.png'
import consul2 from './img/imagen2.png'
import consul3 from './img/imagen3.png'
import consul4 from './img/imagen4.png'
import consul5 from './img/imagen5.png'
import consul6 from './img/imagen6.png'
import ini from './img/ini.png'
import doc from './img/doc.png'
import anterior from './img/anterior1.png'
import siguiente from './img/siguiente2.png'
import docprin from './img/docprin.png'
import blog1 from './img/blog1.png'
import blog2 from './img/blog2.png'
import blog3 from './img/blog3.png'
import rod from './img/rod.png'
import flecha from './img/flecha.png'
import logob from './img/logob.png'

import Servicios from './pages/Servicios';
import Ortodoncia from './pages/Ortodoncia';
import Hope from './pages/Hope'
import Blanqueamiento from './pages/Blanqueamiento';
import Ortopediatria from './pages/Ortopediatria'
import Odontologiaprev from './pages/Odontologiaprev';
import Estetica from './pages/Estetica';
import Endodoncia from './pages/Endodoncia';
import Implantes from './pages/Implantes';
import Protesis from './pages/Protesis';
import Blog from './pages/Blog';
import Cirugia from './pages/Cirugia';
import Contacto from './pages/Contacto'
import Articulo from './pages/Articulo'
import AirFlow from './pages/AirFlow'
import Thanks from './pages/Thanks'
import { useState } from 'react';

function App() {
  const [ face, setFace ] = useState('https://www.facebook.com/DNaturalMexico')
  const [ insta, setInsta ] = useState('https://www.instagram.com/dnatural.mx/')
  const [ tiktok, setTiktok ] = useState('https://www.tiktok.com/@dnatural.mx?is_from_webapp=1&sender_device=pc')

  const [ showPrivacy, setShowPrivacy ] = useState(false)

 return (
    <>
      <div>
        <FloatingWhatsApp 
          phoneNumber="5215561728112"
          accountName="D-Natural"
          className="wa-style"
          statusMessage="En linea"
          avatar={ logob }
          chatMessage="Hola, ¡Bienvenido a D-Natural!. ¿Cómo podemos ayudarte?"
          allowClickAway
          notification={false}
        />

        <header>
          <Navbar fixed="top" bg="light" expand="lg">
            <Container>
              <Navbar.Brand href="#home">
                <img
                  src={logo}
                  height="60"
                  className="d-inline-block align-top logo"
                  alt="React Bootstrap logo"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto"></Nav>
                <Nav className='align-items-center'>
                  <Nav.Link href="inicio" className="nav-link">
                    Inicio
                  </Nav.Link>
                  <Nav.Link href="nosotros" className="nav-link">
                    Nosotros
                  </Nav.Link>
                  <Nav.Link href="ortodoncia" className="nav-link">
                    Ortodoncia sin límites
                  </Nav.Link>
                  <Nav.Link href="hope" className="nav-link">
                    <img src={hop} />
                  </Nav.Link>
                  <Nav.Link href="servicios" className="nav-link">
                    Servicios
                  </Nav.Link>
                  <Nav.Link href="blog" className="nav-link">
                    Blog
                  </Nav.Link>
                  <Nav.Link href="contacto" className="nav-link">
                    Contacto
                  </Nav.Link>
                  <Navbar.Text className="line">
                    <a href={ face } target='_blank'><Facebook className="social-media"></Facebook></a>
                    <a href={ insta } target='_blank'><Instagram className="social-media"></Instagram></a>
                    <a href={ tiktok } target='_blank'><Tiktok className="social-media"></Tiktok></a>
                  </Navbar.Text>
                  <Nav.Link>
                    <Row className="phone">
                      <div>Satélite: (55) 6172 8112 </div>
                    </Row>
                    <Row className="phone">
                      <div>Anzures: (55) 4499 0591</div>
                    </Row>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      </div>

      <div style={{ height: '86px' }}></div>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} /> 
        <Route path="inicio" element={<Home />} /> 
        <Route path="nosotros" element={<Nosotros />} /> 
        <Route path="servicios" element={<Servicios />} /> 
        <Route path="ortodoncia" element={<Ortodoncia />} /> 
        <Route path="hope" element={<Hope />} /> 
        <Route path="blanqueamiento" element={<Blanqueamiento />} />
        <Route path="ortopediatria" element={<Ortopediatria />} />
        <Route path="odontologiaprev" element={<Odontologiaprev />} />
        <Route path="estetica" element={<Estetica />} />
        <Route path="endodoncia" element={<Endodoncia />} />
        <Route path="implantes" element={<Implantes />} />
        <Route path="protesis" element={<Protesis />} />
        <Route path="blog" element={<Blog />} />
        <Route path="cirugia" element={<Cirugia />} />
        <Route path="contacto" element={<Contacto  />} />
        <Route path="airflow" element={<AirFlow  />} />
        <Route path="thanks" element={<Thanks />} />
        <Route path="*" element={<Articulo  />} />
      </Routes>
     </BrowserRouter>

           
      <Container className='section-footer' style={{ maxWidth: '100%' }}>
        <Row className="align-items-center section-footer">
          <Col md={4} xs={ 12 } onClick={ () => setShowPrivacy( true ) } style={{ cursor: 'pointer' }}>Aviso de privacidad</Col>
          <Col md={4} xs={ 12 } style={{ color: '#0cacec'}}>
            {/*Términos y condiciones*/}
          </Col>
          <Col md={4} xs={ 12 }>
            <a href={ face } target='_blank'><Facebook className="social-media-f"></Facebook></a>
            <a href={ insta } target='_blank'><Instagram className="social-media-f"></Instagram></a>
            <a href={ tiktok } target='_blank'><Tiktok className="social-media-f"></Tiktok></a>
            <a href="https://wa.me/5215561728112" target='_blank'><Whatsapp className="social-media-f"></Whatsapp></a>
          </Col>
        </Row>
      </Container>

      <Modal
        show={showPrivacy}
        onHide={() => setShowPrivacy(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ backgroundColor: "#fff" }}>
          <div style={{ fontSize: '25px', fontWeight: 'bold' }}>Aviso de Privacidad</div>
        </Modal.Header>
        <Modal.Body className="footer-privacity" style={{ backgroundColor: "#fff" }}>
          <br/>
          <div>
            NAT EXPERTOS EN CREAR SONRISAS S.C., conocida con el nombre comercial D-Natural, con dos sucursales, SATELITE ubicada en Circuito Científicos 28, 1º piso, Col. Ciudad Satélite, Naucalpan de Juárez, Estado de México, C.P. 53100 y POLANCO ubicada en Gauss 12, 6º piso, Col. Anzures, CDMX, CP. 11590. Es responsable de recabar sus datos personales, del uso que se dé a los mismos y su protección.
            <br/><br/>
            Sus datos personales serán utilizados para proveer los servicios de atención médica dental que ha solicitado, informarle sobre los cambios en los mismos, informarle sobre alguna promoción o beneficio especial y evaluar la calidad del servicio que le brindamos. 
            <br/><br/>
            Para los fines del presente aviso de privacidad, sus datos serán recabados por medio de la HISTORIA CLINICA que el titular nos proporcione, y a través de TOMA DE MODELOS, FOTOGRAFIAS y RADIOGRAFIAS DENTALES o FACIALES que integran su expediente. Para lo cual requerimos los siguientes datos personales: Nombre completo, número telefónico, número celular, correo electrónico, RFC, edad, sexo, estado civil, enfermedades padecidas, tipo de sangre, estado actual de salud, medicamentos que toma actualmente, alergias, tratamientos dentales previos. En caso de ser menor de edad, nombre completo de padre, madre o tutor. 
            <br/><br/>
            NAT EXPERTOS EN CREAR SONRISAS S.C. (D-NATURAL), se compromete a que sus datos serán tratados bajo las más estrictas medidas de seguridad, garantizando su confidencialidad. Sólo serán tratadas por nuestro personal y no serán compartidas con otro tipo de instituciones.
            <br/><br/>
            Este aviso de privacidad también está disponible en www.d-natural.mx y podrá ser modificado por NAT EXPERTOS EN CREAR SONRISAS S.C., por lo que dichas modificaciones podrán ser consultadas en dicha página. 
            <br/><br/>
            El titular de los datos personales podrá dirigirse a NAT EXPERTOS EN CREAR SONRISAS S.C., para el caso de que desee rectificar, limitar o cancelar sus datos personales 
            <br/><br/>
            Fecha de última actualización 18-08-2023
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
}

export default App;
